
<template>
  <SelectMultiAutocompleteLabel
    :isMedium="true"
    v-model="_value"
    :options="selectOptions"
    :name="`checkbox_${_value}`"
  />
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import SelectMultiAutocompleteLabel from '@/components/common/SelectMultiAutocompleteLabel.vue'
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Mixins, PropSync } from 'vue-property-decorator'

@Options({
  components: { SelectMultiAutocompleteLabel },
  name: 'MultiSelect',
})
export default class MultiSelect extends Mixins(Common) {
  @PropSync('modelValue', { type: Array }) _value!: Array<string>
}
</script>
