
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'

@Options({
  components: { InputOnlyNumber },
  name: 'SearchItemBoolean',
})
export default class SearchItemBoolean extends Mixins(SearchItemField) {
  created() {
    this._searchItem.value = this._searchItem.value ?? 'true'
  }
}
