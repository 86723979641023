
<template>
  <SelectAutocompleteLabel :isMedium="true" v-model="_value" :options="selectOptions" :name="`radio_${_value}`" />
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import SelectAutocompleteLabel from '@/components/common/SelectAutocompleteLabel.vue'
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Mixins, PropSync } from 'vue-property-decorator'

@Options({
  components: { SelectAutocompleteLabel },
  name: 'SingleSelect',
})
export default class SingleSelect extends Mixins(Common) {
  @PropSync('modelValue', { type: String }) _value!: string
}
</script>
