<template>
  <el-date-picker class="w-full" v-model="_value" type="daterange" size="medium" :clearable="false"
    :start-placeholder="$t('barcode.startDate')" :end-placeholder="$t('barcode.endDate')" />
</template>

<script lang="ts">
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Options } from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator'
@Options({
  name: 'Date',
})
export default class extends Mixins(Common) {
  @PropSync('modelValue', { type: Array }) _value!: Date[]
}
</script>
