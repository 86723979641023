<template>
  <div>
    <el-select v-model="_searchItem.value" :class="'w-full'" size="medium" :placeholder="$t('selectOne')">
      <el-option key="yes" :label="$t('yes')" value="true"> {{ $t('yes') }} </el-option>
      <el-option key="no" :label="$t('no')" value="false"> {{ $t('no') }} </el-option>
    </el-select>
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'

@Options({
  components: { InputOnlyNumber },
  name: 'SearchItemBoolean',
})
export default class SearchItemBoolean extends Mixins(SearchItemField) {
  created() {
    this._searchItem.value = this._searchItem.value ?? 'true'
  }
}
</script>
