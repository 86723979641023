
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { IBarcodeDefinitionType, IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import SelectAutocompleteLabel from '@/components/common/SelectAutocompleteLabel.vue'

@Options({
  emits: ['change:barcodeType'],
  components: { SelectAutocompleteLabel },
  name: 'SearchItemBarcodeType',
})
export default class SearchItemBarcodeType extends Mixins(SearchItemField) {
  @Prop({ type: Object }) bcTypes!: Record<string, IBarcodeDefinitionType>

  get barcodeTypeOptions() {
    const result = [] as IFieldOption[]
    for (const key in this.bcTypes) {
      result.push({
        value: key,
        label: this.bcTypes?.[key].name ?? '',
      })
    }
    return result
  }

  changeValue() {
    this.$emit('change:barcodeType')
  }
}
