
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { isAfterDate } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: {
    name: 'SearchItemDate',
  },
})
export default class SearchItemDate extends Mixins(SearchItemField) {
  rangeValue: Date[] = []

  @Watch('rangeValue')
  updateSearchItem([from, to]: (Date | { $d: Date })[]) {
    if ('$d' in from && '$d' in to) {
      from = from.$d
      to = to.$d
    }
    this._searchItem.minValue = from
    this._searchItem.maxValue = to
  }

  disabledDate(date: Date) {
    return isAfterDate(new Date(date.valueOf() - 1000 * 3600 * 24)) // tomorrow
  }

  created() {
    this.oldData = cloneDeep(this._searchItem)
    if (this._searchItem.minValue) this.rangeValue.push(this._searchItem.minValue)
    if (this._searchItem.maxValue) this.rangeValue.push(this._searchItem.maxValue)
  }
}
