
<template>
  <el-input :class="'w-full'" size="medium" v-model="_value"></el-input>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Mixins, PropSync } from 'vue-property-decorator'

@Options({ name: 'Input' })
export default class Input extends Mixins(Common) {
  @PropSync('modelValue', { type: String }) _value!: string
}
</script>
