
import { IBarcodeSearchFormItem } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { BARCODE_SEARCH_VALUE_TYPES, TIMETABLE_DATETIME } from '@/utils/constants'
import moment from 'moment'

@Options({
  components: {},
  emits: ['update:searchItem', 'update:valueType'],
  name: 'SearchItemField',
})
export default class SearchItemField extends Vue {
  @PropSync('searchItem', { type: Object }) _searchItem!: IBarcodeSearchFormItem
  oldData = {} as IBarcodeSearchFormItem

  checkDateRang() {
    const isNotValid = moment(this._searchItem.minValue).local().isAfter(moment(this._searchItem.maxValue).local())
    if (isNotValid) {
      this._searchItem = cloneDeep(this.oldData)
    } else {
      this.oldData = cloneDeep(this._searchItem)
    }
  }

  isDateKey(key: string): boolean {
    return key === BARCODE_SEARCH_VALUE_TYPES.date
  }

  isDouble(key: string): boolean {
    return key === BARCODE_SEARCH_VALUE_TYPES.double
  }

  formatDate(date: string) {
    return moment(date).local().format(TIMETABLE_DATETIME.DATE_RESPONSE_FORMAT)
  }
}
