<template>
  <div :class="`w-full ${isCode && isDropdownlist ? 'autocomplete-red-value' : ''}`">
    <el-autocomplete
      v-model="labelShown"
      :fetch-suggestions="queryVariable"
      class="w-full el-select"
      :placeholder="$t('barcode_type.select')"
      icon="edit"
      @blur="isFocus = false"
      @select="handleSelect"
      :size="isMedium ? 'medium' : ''"
      ref="autocomplete"
      :debounce="0"
    >
      <template #suffix v-if="isDropdownlist">
        <i
          :class="`el-select__caret el-input__icon el-icon-arrow-up ${isFocus ? 'is-reverse' : ''}`"
          @click="focusInput"
        >
        </i>
      </template>
      <template #default="{ item }">
        <div>{{ item.label }}</div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script lang="ts">
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue', 'check:IsValueInOptions', 'change:value'],
  name: 'SelectAutoCompleteLAbel',
})
export default class SelectAutocompleteLabel extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: Boolean }) readonly isDropdownlist = true
  @Prop({ type: Array }) readonly options!: IFieldOption[]
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: Boolean, default: false }) readonly isMedium!: boolean
  isFocus = false
  labelShown = ''
  isCode = false
  selectedLabel = ''

  created() {
    this.reloadInitData()
  }

  @Watch('_modelValue')
  @Watch('options')
  reloadInitData() {
    this.isCode = false
    const itemValue = this.options.filter((item) => item.value === this._modelValue)
    if (itemValue.length > 0) {
      this.labelShown = itemValue?.[0]?.label ?? ''
      this.selectedLabel = this.labelShown
    } else {
      if (!isEmpty(this._modelValue)) {
        this.labelShown = this._modelValue || ''
        this.isCode = true
      }
    }
    this.$emit('check:IsValueInOptions', !this.isCode)
  }

  queryVariable(queryString: string, callback: Function) {
    this.isFocus = !isEmpty(this.options)

    if (queryString.trim() === '') {
      this.isCode = false
    } else if (this.selectedLabel === '') {
      this.isCode = true
    } else {
      if (queryString !== this.selectedLabel) {
        this.isCode = true
      }
    }

    if (queryString !== '') {
      this.$emit('check:IsValueInOptions', !this.isCode)
    }

    if (this.isCode) {
      this._modelValue = queryString
      this.$emit('change:value')
    }
    callback(this.options)
  }

  focusInput() {
    this.$refs.autocomplete.focus()
  }

  handleSelect(item: IFieldOption) {
    this.isFocus = false
    this._modelValue = item.value
    this.labelShown = item.label
    this.selectedLabel = item.label
    this.isCode = false
    this.$emit('check:IsValueInOptions', !this.isCode)
    this.$emit('change:value')
  }
}
</script>
<style lang="scss" scoped>
.autocomplete-red-value:deep() {
  &.el-input {
    &__inner {
      color: red;
    }
  }
}
</style>
