
import { IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'Common',
})
export default class Common extends Vue {
  @Prop({ type: Array }) readonly selectOptions!: IFieldOption[]
}
