<template v-loading.fullscreen="loading">
  <div>
    <el-select v-model="_searchItem.value" :class="'w-full'" size="medium" :placeholder="$t('selectOne')">
      <el-option v-for="(item, key) in getUsers" :key="key" :label="item.name" :value="item.id">
        {{ item.name }}
      </el-option>
    </el-select>
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { FETCH_USER_LIST } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'

@Options({ components: {}, name: 'SearchItemUser' })
export default class SearchItemUser extends Mixins(SearchItemField) {
  loading = false
  get getUsers() {
    return this.$store.state.profile?.userList
  }

  async created() {
    if (!this.$store.state.profile.isLoadedUserList) {
      this.loading = true
      await this.$store.dispatch(FETCH_USER_LIST).finally(() => {
        this.loading = false
      })
    }

    this.oldData = cloneDeep(this._searchItem)
  }
}
</script>
