
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { EDimensionKey } from 'smartbarcode-web-core/src/utils/enums/index'

@Options({ components: { InputOnlyNumber }, name: 'SearchItemDimension' })
export default class SearchItemDimension extends Mixins(SearchItemField) {
  created() {
    this._searchItem.minValue = this._searchItem.minValue ? Number(this._searchItem.minValue) : 0
    this._searchItem.maxValue = this._searchItem.maxValue ? Number(this._searchItem.maxValue) : 0
  }

  get hasMaxValue() {
    return this._searchItem.maxValue || this._searchItem.maxValue === 0
  }

  get dimensionKeys() {
    return Object.values(EDimensionKey)
  }
}
