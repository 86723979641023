
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Options } from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator'
@Options({
  name: 'Date',
})
export default class extends Mixins(Common) {
  @PropSync('modelValue', { type: Array }) _value!: Date[]
}
