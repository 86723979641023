
<template>
  <div class="w-1/4">
    <el-select v-model="_searchItem.value" :class="'w-full'" size="medium" :placeholder="$t('selectOne')">
      <el-option v-for="(item, key) in trackPoints" :key="key" :label="item.name" :value="key">
        {{ item.name }}
      </el-option>
    </el-select>
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'

@Options({ components: {}, name: 'SearchItemTrackpoint' })
export default class SearchItemTrackpoint extends Mixins(SearchItemField) {
  @Prop({ type: Object }) trackPoints!: Record<string, ITrackpoint>
  created() {
    this.oldData = cloneDeep(this._searchItem)
  }
}
</script>
