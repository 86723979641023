<template>
  <div class="w-1/4">
    <el-select v-model="_searchItem.subKey" :class="'w-full'" size="medium" :placeholder="$t('selectOne')">
      <el-option v-for="key in dimensionKeys" :key="key" :label="$t(key)" :value="key">
        {{ $t(key) }}
      </el-option>
    </el-select>
  </div>
  <div class="w-1/4">
    <template v-if="hasMaxValue">
      <InputOnlyNumber
        :min="0"
        :max="_searchItem.maxValue"
        v-model="_searchItem.minValue"
        :placeholder="$t('customFields.min')"
        :controls="true"
      />
    </template>
    <template v-else>
      <InputOnlyNumber :min="0" v-model="_searchItem.minValue" :placeholder="$t('customFields.min')" :controls="true" />
    </template>
  </div>
  <div class="w-1/4">
    <InputOnlyNumber
      :min="_searchItem.minValue ?? 0"
      v-model="_searchItem.maxValue"
      :placeholder="$t('customFields.max')"
      :controls="true"
    />
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { EDimensionKey } from 'smartbarcode-web-core/src/utils/enums/index'

@Options({ components: { InputOnlyNumber }, name: 'SearchItemDimension' })
export default class SearchItemDimension extends Mixins(SearchItemField) {
  created() {
    this._searchItem.minValue = this._searchItem.minValue ? Number(this._searchItem.minValue) : 0
    this._searchItem.maxValue = this._searchItem.maxValue ? Number(this._searchItem.maxValue) : 0
  }

  get hasMaxValue() {
    return this._searchItem.maxValue || this._searchItem.maxValue === 0
  }

  get dimensionKeys() {
    return Object.values(EDimensionKey)
  }
}
</script>
