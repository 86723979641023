<template>
  <div :class="`w-full ${isCode ? 'autocomplete-red-value' : ''}`">
    <el-autocomplete
      v-model="autocompleteValue"
      :fetch-suggestions="queryVariable"
      class="w-full el-select"
      :placeholder="$t('barcode_type.select')"
      icon="edit"
      @blur="isFocus = false"
      @select="handleSelect"
      :size="isMedium ? 'medium' : ''"
      ref="autocomplete"
      :debounce="0"
    >
      <template #suffix>
        <i
          :class="`el-select__caret el-input__icon el-icon-arrow-up ${isFocus ? 'is-reverse' : ''}`"
          @click="focusInput"
        >
        </i>
      </template>
      <template #default="{ item }">
        <div>{{ item.label }}</div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script lang="ts">
import { IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'SelectMultiAutoCompleteLabel',
})
export default class SelectMultiAutocompleteLabel extends Vue {
  @PropSync('modelValue', { type: Array }) _modelValue?: string[]
  @Prop({ type: Array }) readonly options!: IFieldOption[]
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: Boolean, default: false }) readonly isMedium!: boolean
  isFocus = false
  autocompleteValue = ''
  isCode = false
  selectedLabel = ''

  created() {
    this.reloadInitData()
  }

  @Watch('_modelValue')
  @Watch('options')
  reloadInitData() {
    this.isCode = false
    const itemValues = this.options.filter((item) => Object.values(this._modelValue ?? []).includes(item.value))
    if (itemValues.length > 0) {
      this.autocompleteValue = itemValues.map((item) => item.label).join(', ')
      this.selectedLabel = this.autocompleteValue
    } else {
      this.autocompleteValue = (this._modelValue || []).filter((item) => item !== '').join(', ') || ''
      if (this.autocompleteValue !== '') {
        this.isCode = true
      }
    }
  }

  queryVariable(queryString: string, callback: Function) {
    if (this.options.length > 0) {
      this.isFocus = true
    } else {
      this.isFocus = false
    }

    if (queryString.trim() === '') {
      this.isCode = false
    } else if (this.selectedLabel === '') {
      this.isCode = true
    } else {
      if (queryString !== this.selectedLabel) {
        this.isCode = true
      }
    }

    if (this.isCode) {
      this._modelValue = queryString.split(',').map((item) => item.trim())
    }
    callback(this.options)
  }

  focusInput() {
    this.$refs.autocomplete.focus()
  }

  handleSelect(item: IFieldOption) {
    let values = this._modelValue ? [...this._modelValue] : []
    if (this.isCode) {
      values = [item.value]
      this.isCode = false
    } else {
      const existIndex = values.indexOf(item.value)
      if (existIndex > -1) {
        values.splice(existIndex, 1)
      } else {
        values.push(item.value)
      }
    }

    const itemValues = this.options.filter((item) => values.includes(item.value))
    this.autocompleteValue = itemValues.map((item) => item.label).join(', ')
    this.selectedLabel = this.autocompleteValue
    this._modelValue = values
    this.isFocus = false
  }
}
</script>
<style lang="scss" scoped>
.autocomplete-red-value:deep() {
  &.el-input {
    &__inner {
      color: red;
    }
  }
}
</style>
