
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import SearchItemField from '@/components/barcodeSearch/SearchItemField.vue'
import { ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'

@Options({ components: {}, name: 'SearchItemTrackpoint' })
export default class SearchItemTrackpoint extends Mixins(SearchItemField) {
  @Prop({ type: Object }) trackPoints!: Record<string, ITrackpoint>
  created() {
    this.oldData = cloneDeep(this._searchItem)
  }
}
