
<template>
  <InputOnlyNumber :min="0" v-model="_value" :controls="true" />
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import Common from '@/components/barcodeSearch/customField/Common.vue'
import { Mixins, PropSync } from 'vue-property-decorator'

@Options({
  components: { InputOnlyNumber },
  name: 'Number',
})
export default class Number extends Mixins(Common) {
  @PropSync('modelValue', { type: Number }) _value!: number
}
</script>
